.AboutPage{
    text-align: center;
}

.bgInfo{
    position: relative;
    width: 100%;
}

.bgInfo img{
    width: 80%;
    max-height: 600px;
    border-radius: 42px ;
    object-fit: cover;
}

.bgContent{
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    top: 60%;
    left: 50%;
    z-index: 2;
    position: absolute;
}

.bgInfo::before{
    content: "";
    width: 80%;
    top:0;
    left: 10%;
    right: 0;
    bottom: 0;
    background: linear-gradient(185.04deg, rgba(0, 0, 0, 0) 30.99%, #6444E6 94.93%);
    border-radius: 42px;
    z-index: 1;
    position: absolute;
}

.bgContent p  {
    color: white !important;
    font-size: 18px !important;
    
}
.bgContent h2{
    color: white ;
    font-size: 5.5vw !important;
}

.work{
    margin-top: 120px;
}
.work p{
    width: 50%;
    margin-left: auto;
    margin-right: auto;
}

.imgWork{
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 33.33333333%;
    gap: 30px;
    justify-content: center;

}
.imgWork img{
    width: 600px;
    transition: transform 0.5s;
    border-radius: 25px;
}


.mobileDisplayBg{
    display: none;
}
.philosophy{
    width: 50%;
    margin-right: auto;
    margin-left: auto;
}
.philosophy h2{
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .bgInfo{
        margin-top: 20px;
    }
    .bgInfo img{
        height: auto;
        width: 90%;
        max-height: 180px;
        border-radius: 10px;
        margin-top: 0;
    }
    
    .bgInfo::before{
        width: 90%;
        left: 5%;
        
    }
    .work p{
        width: 100%;
    }
    .imgWork img{
        width: 80%;
        height: auto;
    }
    .mobileDisplayBg{
        display: block;
    }
    .mobileDisplayBg p{
        margin-left: 7px;
        margin-right: 7px;
        font-size: 18px;
    }
    .philosophy{
        display: none;
    }
    .bgInfo::before{
        border-radius: 10px;
    }
    .mobileHiddenContent{
        display: none;
    }
    .ant-image-mask:hover{
        display: none;
    }
}