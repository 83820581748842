.GamePage {
    text-align: center;
}

.GamePage .backgroundGamePage {
    background-size: cover;
    width: 100%;
    margin-top: 100px;
}

.topGame {
    margin-top: 120px
}

.listToGame {
    margin-top: 40px;
    margin-left: auto;
    margin-right: auto;
    width: 90%;
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    row-gap: 40px;
    justify-content: center;
}

.cardGame {
    transition: transform 0.5s;
    position: relative;
}

.cardGame img{
    border-radius: 20px ;
}

.cardGame .gameInfo {
    position: absolute;
    z-index: 2;
    left: 18px;
    right: 18px;
    bottom: 35px;
}

.gameInfo h3 {
    color: white !important;
    font-weight: 900 !important;
    font-size: 25px;
}

.gameInfo p {
    color: white !important;
   
    font-size: 15px;
    height: 80px;
}

.cardGame::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 28.18%, #1D3982 91.99%);
    z-index: 1;
    border-radius: 20px;
}
.sliderHidden {
    display: none;
}

.cardGame:hover {
    transform: scale(1.1);
}

@media only screen and (max-width: 480px) {
    .cardGame:hover {
        transform: scale(1);
    }

    .listToGame {
        display: none;
    }
    .sliderHidden {
        display: block;
    }
    .slick-list{
        width: 90%;
        margin-right: auto;
        margin-left: auto;
    }
    .cardGame img{
        width: 90%;
        margin-right: auto;
        margin-left: auto;
    }
    .gameInfo{
        width: 80%;
        margin-right: auto;
        margin-left: auto;
    }
    .cardGame::before{
        width: 90%;
        left: 5%;
    }

    
}
@media (min-width:481px) and (max-width:1024px) {
    .listToGame{
        gap: 20px
    }
}
