.cover .slick-slider {
    justify-content: center;
    text-align: center;
    width: 30%;
    margin: auto;
}

.cover img{
    width: 100%;
}

.PartnerText{
   width: 50%;
   margin: auto;
}
@media only screen and (max-width: 900px) {
    .contentCover p{
        font-size: 14px;
    }

    .cover .slick-slider{
        width: 70%;
    }
    .PartnerText{
        width: 90%;
    }
}
