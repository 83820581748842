.listGame{
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 60px;
}

.ourGame p{
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;
}
.nameGame{
    font-weight: 550 !important;
    font-size: 25px !important;
    color: black;
    margin-top: 5px;
}
.card{
    transition: transform 0.5s;
}
.card:hover {
    transform: scale(1.1);
}
.card:hover > p{
    color: black;
    font-weight: 600 !important;
    
}


@media screen and (max-width: 600px) {
    .card img{
        width: 150px;
    }
    .listGame{
        gap: 10px;
    }
    .nameGame{
        font-size: 16px !important;
    }
}