.homePage {
    text-align: center;
    width: 100%;
    
}
.block{
    margin-top: 90px;
}
#slogan {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #1E1C31;
}

#slogan2 {
    color: #1E1C3199;
    font-size: 25px;
    font-weight: 600;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
}

.intro{
    position: relative;
    display: block;
}

.backgroundHome, .backgroundHome-mobile{
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    gap: 30%;
}
@media only screen and (max-width: 900px) {
    .backgroundHome {
        display: none;
    }
    .backgroundHome-mobile {
        display: block;
    }

}
@media only screen and (min-width: 900px) {
    .backgroundHome {
        display: flex !important;
    }
    .backgroundHome-mobile {
        display: none !important;
    }

}

.content{
    width: 30%;
    margin: auto;
    position: absolute ;
    top:0;
    left: 35%;
}
.content h1 {
    font-weight: 700;
    font-size: 60px;
    line-height: 76px;
}

p{
    font-weight: 400;
    font-size: 18px;
    color:#1E1C31;
}
h2 {
    color: #1E1C31;
    font-size: 50px;
    font-weight: 900 !important;
    margin-bottom: 0;
}


.companyBG{
    position: relative;
    width: 100%;
    margin-top: 40px;
}
.companyBG img{
    width: 80%;
    max-height: 600px;
    border-radius: 42px;

}


.companyBG:before{
    content: '';
    position:absolute;
    top: 0;
    right: 0;
    left: 10%;
    bottom: 0;
    z-index: 1;
    width: 80%;
    background: linear-gradient(180deg, rgba(217, 217, 217, 0) 0%, #3550AD 100%);
    border-radius: 42px;
}
@media only screen and (max-width: 1024px) {
    
    .content{
        width: 100%;
        position: relative !important;
        left: 0;
    }
    .backgroundHome{
        flex-direction: row-reverse;
        margin-top: 10px;
        gap: 0;
        
    }
}


@media only screen and (max-width: 600px) {
    .homePage{
        margin-top: 50px;
    }
    h1{
        font-size: 50px !important;
    }
    h2{
        font-size: 32px;
        
    }
    
    .intro img{
        width: 150px;
        height: 260px;
    }
    .backgroundHome img{
        width: 100%;
        height: 400px;

    }
    .companyBG img{
        width: 90%;
        border-radius: 15px ;
        height: auto;
    }
    .companyBG:before{
        border-radius: 15px;
        width: 90%;
        left: 5%;
    }
    
    .content{
        width: 100%;
        position: relative !important;
        left: 0;
    }
    .backgroundHome{
        flex-direction: row-reverse;
        margin-top: 10px;
        gap: -10px;
       
    }
    #slogan2{
        font-size: 15px;
    }
}




