.Footer{
    color: #222136;
    margin: 250px 100px 0 100px;
}

.Footer h5{
    font-weight: bolder !important;
    font-size: 18px;
    line-height: 28px;
}
.inputWithButton{
    position: relative;
    width: 60%;
    margin-bottom: 0;
}

.inputWithButton input{
    background: #ECF0FC;
    border-radius: 9px;
    border-style: hidden;
    height: 40px;
    width: 100%;
    padding: 15px;
    padding-left: 5%;
}
.inputWithButton button{
    border-style: hidden;
    position: absolute;
    width: 30px;
    height: auto;
    background-color: transparent;
    border-radius: 0;
    margin-top: 1%;
    right:10px;
    top: 4.5px
}

.inputWithButton button:hover{
    background-color: transparent;
}

.ant-col{
    width: 100%;
}

.Footer p{
    font-size: 14px !important;
}

.Footer .Icon{
    width: 30px;
    height: 30px;
}

.Footer .logoBottom{
    width: 30%;
}

@media only screen and (max-width: 900px) {
    .Footer{
        color: #222136;
        margin-left: 0;
        margin-right: 0;
        padding-left: 40px;
        padding-right: 40px;
        padding-top: 40px;
        margin-top: 130px;
        margin-bottom: 100px;
        text-align: center;
        justify-content: center;
        background-color: #ECECEC;
    }
    .Footer .logoBottom{
        height: 40px;
        width: auto;
    }
    .ant-col{
        margin-top: 5px;
    }
    .hidden{
        display: none;
    }
    .Icon{
        height: 40px !important;
        width: 40px !important;
    }
    .inputWithButton{
        margin: auto;
    }
}
