.discover {
    text-align: center;
}



.ant-divider {
    background-color: #5E5E5E;
    height: 100%;
}

.discover_info {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 3%;
    justify-content: center;
    align-content: center;
}

.info_detail {
    justify-content:center;
    align-items: center;
}

.detail_numbers {
    font-weight: 900 !important;
    font-size: 43px;
    line-height: 29px;
    color: #EA0000;
    padding-top: 20px;
}

.detail_words {
    font-weight: 400;
    font-size: 22px;
    line-height: 29px;
    color: #787783;
}



@media only screen and (max-width: 600px) {
    

    .detail_numbers {
        font-size: 30px !important;
        
    }

    .detail_words {
        font-size: 18px !important;
    }
    .info_detail {
        height: auto;
        
    }
    .hidden{
        display: none;
    }
    .discover_info {
        gap: 40px;
        flex-direction: column;
    }

}